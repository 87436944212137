import { Box, Button, Card, CardActions, CardContent, CircularProgress, Divider, Grid, Typography } from "@mui/material";
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import propTypes from 'prop-types'

import { useContext, useEffect, useState } from "react"
// import crowdService from "../../../crowdService";
import { Image } from "mui-image";
import { ProofVotes } from "../ProofVotes/ProofVotes";
import { ProofVote } from "../ProofVote/ProofVote";
import { AppContext } from "../../../hooks/AppContext";
import { CryptoAvatar } from "../CryptoAvatar/CryptoAvatar";
import { IPFSText } from "../IPFS/IPFSText";
import ReportIcon from '@mui/icons-material/Report';

export const ProofList = ({service, staker}) => {
    const {crowdServiceUpdated, updateCrowdService, account, manager, crowdService} = useContext(AppContext)
    const [proofs, setProofs] = useState(null);
    const [canAcceptProof, setCanAcceptProof] = useState(false);
    const [isAcceptingProof, setIsAcceptingProof] = useState(false);
    const [isReportingProof, setIsReportingProof] = useState(false);


    useEffect(() => {
        let isCleaning = false;
        const load = async () => {
            const proofsIds = await crowdService.methods.showProofs(service.id).call();

            var proofs = [];
            for(var i = 0; i < proofsIds.length; i++)
            {
                var proof = await crowdService.methods.getProof(service.id, proofsIds[i]).call();
                proofs.push(proof);
            }
            if(!isCleaning) {
                setProofs(proofs.reverse());
            }


            var canAcceptAny = false;
            if(manager?.toLowerCase() === account?.toLowerCase() || service.createdBy?.toLowerCase() === account?.toLowerCase()) {
                canAcceptAny = true;
            } else if( service.requireVotePercents > 0) {
                canAcceptAny = true;
            }            

            setCanAcceptProof(canAcceptAny)

        };
    
        if(service && crowdService) {
            load();
        }

        return () => {
            isCleaning = true;
        };

    }, [service, crowdServiceUpdated, account, manager, crowdService])
    
    const acceptProof = async (proofCreatedBy) => {
        if(account) {
            setIsAcceptingProof(true);
            try {
                await crowdService.methods.acceptProof(service.id, proofCreatedBy).send({
                    from: account,
                });
            } catch (err) {
                console.log(err.message);
            }
            setIsAcceptingProof(false);
            updateCrowdService();
        }
    };

    const reportProof = async (proofCreatedBy) => {
        if(account) {
            setIsReportingProof(true);
            await crowdService.methods.denyProof(service.id, proofCreatedBy).send({
                from: account,
            });
            setIsReportingProof(false);
            updateCrowdService();
        }

    };


    return (
        <>
        { proofs && proofs.length > 0 && <Box my={2}>
        <Typography variant="h5" component="h4" mb={2}>Proofs</Typography>

        {proofs && proofs.map((proof, index) => {

             return (<Card key={proof.createdBy} sx={{ marginBottom: '10px'}}>
                <CardContent>
                    { proof.payed && <PriceCheckIcon fontSize="large" htmlColor="green" style={{float: 'right'}} />}

                    <Grid container spacing={3}>
                        {   proof.image &&
                            <Grid item sm={4}>
                            {  proof.image && <Image  
                                src={proof.image.startsWith('http') ? proof.image : `https://ipfs.infura.io/ipfs/${proof.image}`} 
                                fit="contain"
                                style={{ objectPosition: 'top', maxWidth: '100%', width: 'auto'}}
                                width="auto"
                             /> }
                            </Grid>
                    } 
                        <Grid item sm={proof.image ? 8 : 12}>
                            <Typography component="div">{new Date(proof.createdTime *1000).toLocaleDateString()}</Typography>
                            {  proof.text && <IPFSText textOrCid={proof.text} /> }

                            {   service.requireVotePercents > 0 &&
                                <>
                                    <Divider sx={{ marginTop: '10px', marginBottom: '10px'}}/>
                                    <ProofVotes serviceId={service.id} proofAddress={proof.createdBy} />
                                </>
                            }

                            { !proof.payed && staker?.denyProofs &&
                                <CardActions sx={{marginTop: '10px'}}>
                                    <CryptoAvatar address={proof.createdBy} /> &nbsp;&nbsp;

                                    { canAcceptProof && 
                                        <>
                                            <Divider orientation="vertical" flexItem />
                                            <Button disabled={isAcceptingProof} onClick={() => acceptProof(proof.createdBy)}>Accept{ isAcceptingProof && <CircularProgress size="small" />}</Button>
                                        </>
                                    }

                                    { service.requireVotePercents > 0 &&
                                        <>
                                            <Divider orientation="vertical" flexItem />
                                            <ProofVote serviceId={service.id} proofAddress={proof.createdBy} staker={staker} />                                    
                                            <Button disabled={isReportingProof || staker.denyProofs.includes(proof.createdBy)} onClick={() => reportProof(proof.createdBy)}  color="warning"><ReportIcon />Report { isReportingProof && <CircularProgress size="10px" />}</Button>
                                        </>
                                    }

                                </CardActions>
                            }
                        </Grid>
                    </Grid>

                </CardContent>
             </Card>)
          })}    
    </Box>}
    </>
    )
}

ProofList.propTypes = {
    service: propTypes.any,
    staker: propTypes.any
}