import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import { Box, Typography, Stack, Drawer, List, ListItemText, ListItemButton, Grid, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { WalletConnect } from '../WalletConnect/WalletConnect';
export const Header = (props) => {

  const theme = useTheme();
  const anchor = 'left';
  const [openDrawer, setOpenDrawer] = useState(false);

  const toggleDrawer = (open, event) => {
    if (
      event.type === 'keydown' &&
      ((event).key === 'Tab' ||
        (event).key === 'Shift')
    ) {
      return;
    }

    setOpenDrawer(open);
  };

  return (<Box sx={{ flexGrow: 1, position: 'fixed', top: 0, width: '100%', zIndex: 1000 }}>
      <AppBar position="static" sx={{backgroundColor: theme.palette.primary.main + 'f0', backdropFilter: 'blur(10px)'}}>
        <Toolbar variant="regular">
          
          <Grid container display="block">
            <Grid sx={{float: 'left'}}>
              <IconButton onClick={(e) => {
                toggleDrawer(true, e);
              }} edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
                <MenuIcon />
              </IconButton>

              <Drawer
                anchor={anchor}
                open={openDrawer}
                onClose={(e) => {toggleDrawer(false, e);}}
                >
                <Box
                  style={{backgroundColor: theme.palette.primary.dark, color: 'white'}}                  
                  sx={{ width: 250, height: '100%' }}
                  role="presentation"
                  onClick={() => toggleDrawer(anchor, false)}
                  onKeyDown={() => toggleDrawer(anchor, false)}
                >
                  <List >
                    {[
                    {title: 'Toplist', url: '/'}, 
                    {title: 'Payouts', url: '/payouts'}, 
                    {title: 'About', url: '/about'}, 
                    {title: 'Contract', url: '/contract'},
                    {title: 'Tokenomics', url: '/tokenomics'}
                  ].map((link, index) => (
                      <ListItemButton component="a" key={link.title} href={link.url}>
                        <ListItemText primary={link.title} />
                      </ListItemButton>
                    ))}
                  </List>
                </Box>
              </Drawer>

            </Grid>

            <Grid sx={{float: 'right', textAlign: 'right'}} >
              <WalletConnect />
            </Grid>


            <Grid item xs={8} margin="auto">

              <Stack 
                width={'8s0%'}
                direction="row"
                justifyContent="center"
                minHeight='40px'
                whiteSpace="pre"
                alignItems="center" color={'#FFD700'}>
                    <Box sx={{ display: { xs: 'none', md: 'inline-block' }, verticalAlign: 'baseline'}}>
                      { process.env.REACT_APP_THEME === 'kitty' ? 
                      <>
                      <img src="/static/kitty/kitty-zombie.png" alt="" height="35px" />
                      <img src="/static/kitty/kitty-pink.png" alt="" height="35px" />
                      <img src="/static/kitty/kitty-knife.png" alt="" height="35px" />
                      </> : 
                      <><AccessibilityNewIcon /><AccessibilityNewIcon /><AccessibilityNewIcon /></> }                      
                    </Box>
                    <Box sx={{verticalAlign: 'baseline'}}>
                      { process.env.REACT_APP_THEME === 'kitty' ? 
                        <>
                          <img src="/static/kitty/kitty-ninja.png" alt="" height="35px" />
                          <img src="/static/kitty/kitty-painter.png" alt="" height="35px" />
                      </>
                      : <>
                        <AccessibilityNewIcon sx={{height: {xs: '20px', sm: 'auto'}, display: 'inline-block'}} />
                        <AccessibilityNewIcon sx={{height: {xs: '20px', sm: 'auto'}}} />
                      </>}
                    </Box>

                    { process.env.REACT_APP_THEME === 'kitty' ? 
                      <>
                        <Typography variant="h4" component="h1" sx={{ display: { xs: 'none', sm: 'block' } }}>
                          <Link to="/" style={{color: '#FFD700', textDecoration: 'none'}} color={'#FFD700'} >KittyCr<img width="20px" style={{margin: '0 2px', filter: 'drop-shadow(1px 1px 3px rgba(0,0,0,0.5))'}} src="/logo512.png" alt="" />wd</Link>
                        </Typography>

                        <Typography variant="h5" component="h1" sx={{ display: { xs: 'block', sm: 'none' } }}>
                          <Link to="/" style={{color: '#FFD700', textDecoration: 'none'}} color={'#FFD700'} >KittyCr<img width="13px" style={{margin: '0 2px', filter: 'drop-shadow(1px 1px 3px rgba(0,0,0,0.5))'}} src="/logo512.png" alt="" />wd</Link>
                        </Typography>
                      </> :
                      <>
                        <Typography variant="h4" component="h1" sx={{ display: { xs: 'none', sm: 'block' } }}>
                          <Link to="/" style={{color: '#FFD700', textDecoration: 'none'}} color={'#FFD700'} >Cr<img width="20px" style={{margin: '0 2px', filter: 'drop-shadow(1px 1px 3px rgba(0,0,0,0.5))'}} src="/logo512.png" alt="" />wdService</Link>
                        </Typography>

                        <Typography variant="h5" component="h1" sx={{ display: { xs: 'block', sm: 'none' } }}>
                          <Link to="/" style={{color: '#FFD700', textDecoration: 'none'}} color={'#FFD700'} >Cr<img width="13px" style={{margin: '0 2px', filter: 'drop-shadow(1px 1px 3px rgba(0,0,0,0.5))'}} src="/logo512.png" alt="" />wdService</Link>
                        </Typography>
                      </> }



                    <Box sx={{verticalAlign: 'baseline'}}>
                    { process.env.REACT_APP_THEME === 'kitty' ? 
                        <>
                          <img src="/static/kitty/kitty-painter.png" alt="" height="35px" />
                          <img src="/static/kitty/kitty-ninja.png" alt="" height="35px" />
                      </>
                      : <>
                        <AccessibilityNewIcon sx={{height: {xs: '20px', sm: 'auto'}, display: 'inline-block'}} />
                        <AccessibilityNewIcon sx={{height: {xs: '20px', sm: 'auto'}}} />
                      </>}
                    </Box>
                    <Box sx={{ display: { xs: 'none', md: 'inline-block' }}}>
                    { process.env.REACT_APP_THEME === 'kitty' ? 
                      <>
                      <img src="/static/kitty/kitty-knife.png" alt="" height="35px" />
                      <img src="/static/kitty/kitty-pink.png" alt="" height="35px" />
                      <img src="/static/kitty/kitty-zombie.png" alt="" height="35px" />
                      </> : 
                      <><AccessibilityNewIcon /><AccessibilityNewIcon /><AccessibilityNewIcon /></> }                      
                    </Box>
              </Stack>

            </Grid>
          </Grid>
          
        </Toolbar>
      </AppBar>
    </Box>);
}