import React, { useEffect, useContext, useState, Fragment } from 'react';
import propTypes from 'prop-types'
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { Box } from '@mui/material';
import { TimelineOppositeContent } from '@mui/lab';
import { AppContext } from '../../../hooks/AppContext';


export const ServiceTimeline = ({service, staker}) => {
    const { crowdServiceUpdated, account, crowdService } = useContext(AppContext);

    const [stakersBefore, setStakersBefore] = useState(0);
    const [stakersAfter, setStakersAfter] = useState(0);
    const [proofs, setProofs] = useState([]);
    const [acceptedProof, setAcceptedProof] = useState();


    useEffect(() => {
        var isCleaning = false;

        const load = async () => {
            var stakers = await crowdService.methods.showStakers(service.id).call();

            if (staker && staker.value > 0) {
                var position = stakers.indexOf(staker.createdBy)

                setStakersBefore(position);
                setStakersAfter(stakers.length - (position + 1));
            } else 
            {
                setStakersBefore(stakers.length);
            }

            var returnProof = await crowdService.methods.showProofs(service.id).call();
            if(isCleaning) return;
            console.log('proofs' ,returnProof);

            var addedProofs = [];
            // returnProof.forEach(async (p) => {
            //     console.log(service.id, p)
            //     var proof = await crowdService.methods.getProof(service.id, p + '').call();
            //     if(isCleaning) return;
 
            //     addedProofs.push(proof);
            //     console.log(addedProofs);
            // });

            for(const p of returnProof) {
                const proof = await crowdService.methods.getProof(service.id, p).call();
                console.log(proof);
                addedProofs.push(proof);
            }

            console.log('added proofs', addedProofs);
            setProofs(addedProofs);

            console.log(addedProofs);

            const isEmpty =  /^0x0+$/.test(service.acceptedProof);
            if (!isEmpty) {
                var accepted = await crowdService.methods.getProof(service.id, service.acceptedProof).call();
                if(isCleaning) return;
 
                setAcceptedProof(accepted);
                   console.log(accepted, 'accepted');        
            }
        };

        console.log(crowdServiceUpdated, account, crowdService, service, staker);

        if(service) {
            load();
        }
      return () => {
          isCleaning = true;
      }
    }, [crowdServiceUpdated, account, crowdService, service, staker])
    

    return (<>   
        {
            <Box>
                <Timeline>
                { service &&
                    <TimelineItem>
                        <TimelineOppositeContent color="text.secondary" fontSize="12px">
                            {new Date(service.createdTime * 1000).toLocaleDateString()}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot color="success" />
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>Service started</TimelineContent>
                    </TimelineItem>
                }

                { stakersBefore > 0 &&
                    <TimelineItem>
                        <TimelineSeparator>
                            <TimelineDot color="success" />
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent> {stakersBefore} joined</TimelineContent>
                    </TimelineItem>
                }

                { staker && staker.value > 0 &&
                    <>
                    <TimelineItem>
                        <TimelineOppositeContent color="text.secondary" fontSize="12px">
                            {new Date(staker.createdTime * 1000).toLocaleDateString()}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                        <TimelineDot color="success" />
                        <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>You joined</TimelineContent>
                    </TimelineItem>

                    { staker.createdTime !== staker.updatedTime &&
                    <TimelineItem>
                        <TimelineOppositeContent color="text.secondary" fontSize="12px">
                            {new Date(staker.updatedTime * 1000).toLocaleDateString()}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                        <TimelineDot color="success" />
                        <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>Your latest add</TimelineContent>
                    </TimelineItem>
                    }


                    { stakersAfter > 0 &&
                    <TimelineItem>
                        <TimelineSeparator>
                            <TimelineDot color="success" />
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent> {stakersAfter} joined</TimelineContent>
                    </TimelineItem>
                    }



                    { staker && staker.value > 0 &&
                    <TimelineItem>
                        <TimelineOppositeContent color="text.secondary" fontSize="12px">
                            {new Date(staker.endTime * 1000).toLocaleDateString()}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                        <TimelineDot color="warning" />
                        <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>Your timelock ends</TimelineContent>
                    </TimelineItem>
                    }
                    </>
                }

                { proofs && proofs.map((proof, index) => (
                   <Fragment key={'proof-' + index}>
                        <TimelineItem>
                            <TimelineOppositeContent color="text.secondary" fontSize="12px">
                                {new Date(proof.createdTime * 1000).toLocaleDateString()}
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot color="info" />
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>Proof added</TimelineContent>
                        </TimelineItem>
                    </Fragment>
                ))}

                { acceptedProof ?
                <TimelineItem>
                    <TimelineOppositeContent color="text.secondary" fontSize="12px">
                        {new Date(acceptedProof.updatedTime * 1000).toLocaleDateString()}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                    <TimelineDot color="error" />
                    </TimelineSeparator>
                    <TimelineContent>Service ended</TimelineContent>
                </TimelineItem> :
                <TimelineItem>
                    <TimelineSeparator>
                        <TimelineDot color="info" />
                    </TimelineSeparator>
                    <TimelineContent>Active</TimelineContent>
                </TimelineItem>
                }
                </Timeline>
            </Box>
        }
    </>)
};

ServiceTimeline.propTypes = {
    service: propTypes.any,
    staker: propTypes.any
};