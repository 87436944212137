import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import { Image } from 'mui-image';
import { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom'
// import crowdService from '../../../crowdService';
import web3 from '../../../web3';
import { AddProof } from '../../Shared/AddProof/AddProof';
import { ProofList } from '../../Shared/ProofList/ProofList';
import { Stake } from '../../Shared/Stake/Stake';
import LockClockIcon from '@mui/icons-material/LockClock';
import { AppContext } from '../../../hooks/AppContext';
import { IPFSText } from '../../Shared/IPFS/IPFSText';
import { CryptoAvatar } from '../../Shared/CryptoAvatar/CryptoAvatar';
// import { StakerChart } from '../../Shared/StakerChart/StakerChart';
import { ServiceTimeline } from '../../Shared/ServiceTimeline/ServiceTimeline';




export const Service = () => {
    const { serviceId } = useParams()
    const [service, setService] = useState(null);
    const [staker, setStaker] = useState(null);
    const [isEnded, setIsEnded] = useState(false);
    const [canAddProof, setCanAddProof] = useState(false);

    const { crowdServiceUpdated, account, crowdService } = useContext(AppContext);
    const fallbackImage = 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/Flag_of_Ukraine.svg/2560px-Flag_of_Ukraine.svg.png';


    useEffect(() => {
        const load = async () => {

            const service = await crowdService.methods.services(serviceId).call();
            setService(service);

            const isEmpty =  /^0x0+$/.test(service.acceptedProof);
            setIsEnded(!isEmpty);

            const proofAddresses = await crowdService.methods.showProofs(service.id).call();
            if(proofAddresses.length === 1 && proofAddresses[0] === service.createdBy)
            {
                var proof = await crowdService.methods.getProof(serviceId, service.createdBy).call();
                setCanAddProof(proof.createdTime !== service.createdTime);
            }
            else {
                setCanAddProof(true);
            }

            if( account ) {
                var st = await crowdService.methods.getStake(serviceId).call({
                    from: account
                });
                setStaker(st);
            }
        };
        if(crowdService){
            load();
        }

    }, [serviceId, crowdServiceUpdated, account, crowdService])
    

    return (<>
        {service && <>
            <Grid container spacing={4}>
                <Grid item sm={4} my={0} sx={{
                    paddingLeft: {xs: '0 !important', sm: '32px !important'},
                    paddingRight: {xs: '0 !important', sm: '32px !important'},
                    paddingTop: {xs: '0 !important', sm: '32px !important'},
                    marginLeft: {xs: '0'},
                    height: 'auto !important',
                    alignItems: 'start !important'

                }}>
                    { service.image ? 
                        <Image src={service.image.startsWith('http') ? service.image : `https://ipfs.infura.io/ipfs/${service.image}`} fit="contain" height="auto" sx={{ 
                            objectPosition: 'top', 
                            width: {xs: '100vw !important', sm: '100% !important'},
                            // height: 'auto !important',
                            marginLeft: {xs: '16px'},
        
                        }} />
                        : <Image src={fallbackImage}
                        fit="contain" sx={{ 
                            objectPosition: 'top', 
                            width: {xs: '100vw !important', sm: '100% !important'},
                            // height: 'auto !important',
                            marginLeft: {xs: '16px'},
        
                        }} />
                    }
                    <Box sx={{ display:{xs: 'none', md: 'block'}}}>
                        <ServiceTimeline service={service} staker={staker} />
                    </Box>

                </Grid>
                <Grid item sm={8} my={0}>
                    <Typography component="div" mt={0}>{new Date(service.createdTime * 1000).toLocaleDateString()}</Typography>
                    <Typography component="h1" variant='h4'>{service.title}</Typography>

                    { service.text && <IPFSText textOrCid={service.text} /> }


                    { service.link &&
                        <>
                        <Divider py={4} sx={{paddingTop: 1, paddingBottom: 1}} />
                        <Button href={service.link} target="_blank" rel="noreferrer" size="large" color="primary" sx={{wordBreak: 'break-all'}}>{service.link.toLowerCase()}</Button>
                        </>
                    }

                    {/* <StakerChart serviceId={serviceId} /> */}

                    <Typography variant="h6" color="text.secondary">
                        <br />
                        <LockClockIcon fontSize='medium' />  Value locked: <Typography variant="h6"  component="span" fontWeight={'bold'}>{web3.utils.fromWei(service.totalValue, 'ether')}</Typography> BNB<br />
                        Requires <strong>{service.requireVotePercents}%</strong> votes to approve a proof! 
                    </Typography>

                    <CryptoAvatar address={service.createdBy} />

                    <Box sx={{ display:{xs: 'block', md: 'none'}}}>
                        { <ServiceTimeline service={service} staker={staker} /> }
                    </Box>

                    <br />
                    {staker && staker.value > 0 && <> Your are helping with: <Typography component="span" fontWeight={'bold'}>{web3.utils.fromWei(staker.value, 'ether')}</Typography> BNB <br /></>}
                    {staker && staker.value > 0 && <> Your timelock will end {new Date(staker.endTime * 1000).toLocaleDateString()} and you will loose you vote</>}

                    <br />
                    <br />
                    <br />
                    { !isEnded &&
                        <>
                            <Stake serviceId={serviceId} staker={staker} />
                            {
                                canAddProof &&
                                <AddProof serviceId={serviceId} />
                            }
                        </>
                    }        
                    <ProofList service={service} staker={staker} />
                    <br /><br />
                    <br /><br />
                </Grid>

            </Grid>


        </>}


    </>)
};
