import { Box, Button, CircularProgress, TextField, Typography } from '@mui/material';
import { useEffect, useState, useContext } from 'react';
import crowdService from '../../../crowdService';
import web3 from '../../../web3';
import cwdsAbi from '../../../cwdsAbi';
import { AppContext } from '../../../hooks/AppContext';


export const ContractInfo = () => {
  const {crowdServiceUpdated, updateCrowdService, account} = useContext(AppContext)

  const [cwdsToken, setCWDSToken] = useState('');
  const [tokenSupply, setTokenSupply] = useState(0);
  const [yourBalance, setYourBalance] = useState(0);
  const [cwdsService, setCwdsService] = useState(null);
  const [burnValue, setBurnValue] = useState('');
  const [isBurning, setIsBurning] = useState(false);

  const burn = async () => {
    setIsBurning(true);

    await cwdsService.methods.burn( web3.utils.toWei(burnValue.toString(), 'ether')).send({
        from: account,
    });

    setBurnValue('');
    setIsBurning(false);    
    updateCrowdService();
  };


    useEffect(() => {
      const loadTags = async () => {
        const token = await crowdService.methods.token().call();
        setCWDSToken(token);

        const cwdsService = new web3.eth.Contract(cwdsAbi, token);
        setCwdsService(cwdsService);

        var totalSupply = await cwdsService.methods.totalSupply().call();
        setTokenSupply(web3.utils.fromWei(totalSupply, 'ether'));
      };
      loadTags();
    }, [crowdServiceUpdated]);

    useEffect(() => {
      const load = async () => {
        var yourBalance = await cwdsService.methods.balanceOf(account).call();
        setYourBalance(web3.utils.fromWei(yourBalance, 'ether'));
      };
    
      if(account && cwdsService) {
        load();
      }
    }, [crowdServiceUpdated, cwdsService, account]);

    return (<>

        <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h2" gutterBottom>
          Information about the contract
        </Typography>
        <Typography>
          Add token to you wallet and start trading your rewards or start investing in the project<br />
          <br />

          Token Name: <strong>CrowdService Token</strong><br />
          Symbol: <strong>CWDS</strong><br />
          Contract:<br />
          <strong><small>{cwdsToken}</small></strong><br />
          Decimals: <strong>18</strong><br />
          Total Supply: <strong>{tokenSupply} CWDS</strong><br />
          Your Balance: <strong>{yourBalance} CWDS</strong>          
        </Typography>
        <br />

        {/* <Typography variant="h4" component="h2" gutterBottom>
          Trade CWDS Token
        </Typography>
        <Typography>
          <Typography variant="h5" component="h3">DEX</Typography>
          You can buy CWDS Tokens on PancakeSwap here<br />
          <Link href="https://pancakeswap.finance/swap?outputCurrency=0xd83ABd114fF3FA1038D75D54878CceCDcc6F9afD">PancakeSwap <img src="/static/pancake.svg" alt="PancakeSwap"/></Link><br />
          <br />
          <Typography variant="h5" component="h3">Liquidity Pool</Typography>
          Help the project and earn fees by joining PancakeSwap Liquidity Pool<br />
          <Link href="https://pancakeswap.finance/add/0xd83ABd114fF3FA1038D75D54878CceCDcc6F9afD/BNB">PancakeSwap LP CWDS/BNB <img src="/static/pancake.svg" alt="PancakeSwap"/></Link>
        </Typography><br /> */}
        <br />
        <Typography variant='h5' component="h2">
          Tokenomics
        </Typography>
        <Typography>
          Stakers timelock their BNB.<br />
          <br />
          95% of BNB are paid out to the accepted address that has created the proof<br />
          CWDS rewards are paid out to stakers. 1BNB=1CWDS<br />
          3% of BNB fees are used for buying back and burning CWDS tokens<br />
          2% are kept for dev and marketing
        </Typography>


        <Box my={4}>
          <form onSubmit={burn}>
            <Typography variant='h6' component="h3">
              You can burn your tokens here: ({yourBalance} CWDS)
            </Typography>
            <TextField label="CWDS Tokens to burn!"variant="outlined" inputProps={{ type: 'number', step: '0.000001' }} value={burnValue} onChange={(e) => setBurnValue(e.target.value)} sx={{ mb: 2, width: '100%' }} />
            <br />
            { !isBurning ? <Button disableRipple={false} variant="contained" onClick={() => {
                    burn();
                }}>
                    Burn
                </Button> : 
                <Button disabled disableRipple={false} variant="contained">
                    Burn <CircularProgress size={10} />
                </Button>
            }
          </form>

        </Box>
        <br  />

        <Typography variant='h4' component="h2">
          In the planning
        </Typography>

        <Typography variant='h5' component="h3">
          Trading CWDS Token on DEX/CEX
        </Typography>
        <ul>
          <li>PancakeSwap: WIP</li>
          <li>What CEX to trade on?</li>
        </ul>

        <Typography variant='h5' component="h3">
        Add to more chains
        </Typography>
        <ul>
          <li>Ethereum?</li>
        </ul>

        <Typography variant='h5' component="h3">
            Add supportchannels
        </Typography>
        <ul>
          <li>Telegram?</li>
        </ul>

        <Typography variant='h5' component="h3">
          Audit
        </Typography>
        <ul>
          <li>CertiK</li>
        </ul>

      </Box>
    </>)
};