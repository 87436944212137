import { CreateAndStake } from "../../Shared/CreateAndStake/CreateAndStake";
import { TopList } from "../../Shared/TopList/TopList";
import { USP } from "../../Shared/USP/USP";
import React from 'react';



export const Start = () => {
 
  

    return (<>
        <USP />
        <CreateAndStake />
        <TopList />
     </>)
};