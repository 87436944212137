import * as React from 'react';
import web3 from './../../../web3';
// import crowdService from './../../../crowdService';
import { useEffect, useState, useContext } from 'react';
import { Box, Link, Typography } from '@mui/material';
import LockClockIcon from '@mui/icons-material/LockClock';
import { AppContext } from '../../../hooks/AppContext';
import TwitterIcon from '@mui/icons-material/Twitter';



export const USP = () => {
    const [totalStakedValue, setTotalStakedValue] = useState(0);
    const {updateCrowdService, crowdService} = useContext(AppContext)

    useEffect(() => {    
        const load = async () => {
          const stakedValue = await crowdService.methods.totalStakedValue().call();
          setTotalStakedValue(stakedValue);            
        };
        if(crowdService) {
          load();
        }
    }, [updateCrowdService, crowdService])

    return (<Box sx={{ my: 4 }}>
        <Typography variant="h5" component="h2" gutterBottom>
        Join the crowd and do something!
        </Typography>
        <ul>
          <li>This smart contract on Binance Smart Chain makes it easy to join hands and crowdfund anything.</li>
          <li>Timelock you BNB for a good cause and  earn some CWDS Tokens in reward.</li>
          <li>Perform a service, add proof and get rewards in BNB</li>
        </ul>


        <video width="400" controls preload="metadata">
            <source src="/static/crowdservice.mp4#t=1.5" type="video/mp4" />
            Your browser does not support HTML video.
        </video>


        <Box sx={{ mb: 2, width: '100%' }}>
          { totalStakedValue > 0 && 
            <Typography variant='h6' component="h2">
              <LockClockIcon fontSize='medium' /> Total value locked: <Typography variant='h6' component="span" fontWeight={'bold'} >{web3.utils.fromWei(totalStakedValue, 'ether')}</Typography> BNB
            </Typography>          }
        </Box>

        <Box>
          <Link href="https://twitter.com/crowd_service" target="_blank" color="#1DA1F2" mr={2}><TwitterIcon fontSize='large' /></Link>
          <Link href="https://discord.com/channels/968962242404044900/968962242404044903" target="_blank" mr={2}><img src="/static/discord.svg" alt="Discord" height="35px"/></Link>
          <Link href="https://pancakeswap.finance" target="_blank" color="#1DA1F2" ><img src="/static/pancake.svg" alt="PancakeSwap: WIP" height="25px" style={{marginBottom: '4px'}} /></Link>
          {/* <Link href="https://pancakeswap.finance/swap?outputCurrency=0xd83ABd114fF3FA1038D75D54878CceCDcc6F9afD" target="_blank" color="#1DA1F2" ><img src="/static/pancake.svg" alt="PancakeSwap" height="25px" style={{marginBottom: '4px'}} /></Link> */}
        </Box>

      </Box>);
}