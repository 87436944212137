import React, { useContext } from 'react';
import web3 from '../../../web3';
// import crowdService from '../../../crowdService';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Typography, Button, Divider, CircularProgress, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea, CardActions } from '@mui/material';
import { Masonry } from '@mui/lab';
import { Image } from 'mui-image';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import LockClockIcon from '@mui/icons-material/LockClock';
import { AppContext } from '../../../hooks/AppContext';
import { CryptoAvatar } from '../CryptoAvatar/CryptoAvatar';

export const PayoutList = () => {
    const [services, setServices] = useState(null);
    const navigate = useNavigate();
    const {crowdServiceUpdated, crowdService} = useContext(AppContext)

    const [filter, setFilter] = useState('toplist');


    useEffect(() => {    
        const load = async () => {
          const serviceIds = await crowdService.methods.showPayedServiceIds().call();
  
          var services = [];
          for(var i = 0; i < serviceIds.length; i++)
          {
            const service = await crowdService.methods.services(serviceIds[i]).call();
            services.push(service);
          }


          if(filter === 'toplist') {
            services.sort((a, b) => {
              if(a.totalValue-0 < b.totalValue-0)
              {
                return 1;
              }
              else if(a.totalValue-0 === b.totalValue-0)
              {
                return 0;
              }
              return -1              
            });
          }
          else if (filter === 'created') {
            services.sort((a, b) => {return a.createdTime < b.createdTime ? 1 : a.createdTime === b.createdTime ? 0 : -1});              
          }
          else if (filter === 'updated') {
            services.sort((a, b) => {return a.updatedTime < b.updatedTime ? 1 : a.updatedTime === b.updatedTime ? 0 : -1});              
          }

          setServices(services);  
        };
        if(crowdService) {
          load();
        }
      }, [crowdServiceUpdated, filter, crowdService])

      const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };


    return (<Box>

      <FormControl sx={{ my: 4, minWidth: 120, float: 'right' }}>
            <InputLabel id="demo-simple-select-helper-label">Filter</InputLabel>
            <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={filter}
                label="Filter"
                onChange={handleFilterChange}
        >
          <MenuItem value="toplist">Toplist (Value)</MenuItem>
          <MenuItem value="created">Latest Created</MenuItem>
          <MenuItem value="updated">Latest Updated</MenuItem>
        </Select>
      </FormControl>
        
        { services ?
        <Masonry columns={{ sm: 1, md: 2 }} spacing={5} >
        {services.map((service, index) => {
          if ( service.text !== '' )
          {
            return <Card key={service.id} sx={{ mb: 2 }}>
            <CardActionArea onClick={() => {
              navigate('/'+service.id);
            }} >

              { service.image && 
                <Image
                  fit="contain"
                  width="100%"
                  src={service.image.startsWith('http') ? service.image : `https://ipfs.infura.io/ipfs/${service.image}`}
                 />
              }

              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  <LockClockIcon fontSize='small' /> <Typography component="span" fontWeight={'bold'}>{web3.utils.fromWei(service.totalValue, 'ether')}</Typography> ETH
                </Typography>

                <PriceCheckIcon fontSize="large" htmlColor="green" style={{float: 'right'}} />

                <Typography gutterBottom variant="h5" component="h3">
                  {service.title}
                </Typography>

              </CardContent>

            </CardActionArea>
            <CardActions>
              <CryptoAvatar address={service.createdBy} /> &nbsp;&nbsp;
              <Divider orientation="vertical" flexItem />
              {
                service.link &&
                <>
                  <Button href={service.link} target="_blank" rel="noreferrer" size="small" color="primary">Link</Button>
                  <Divider orientation="vertical" flexItem />
                </>
              }
              <Typography mx={2} component="span">{new Date(service.createdTime *1000).toLocaleDateString()}</Typography>            
            </CardActions>

          </Card>

          }
          return null;
        })}
      </Masonry>
        : <CircularProgress my={4} />}
        

      </Box>)
}