import React from 'react';
// import crowdService from '../../../crowdService';
import { useState, useContext } from 'react';

import { Typography, Button, Accordion, AccordionSummary, AccordionDetails, TextareaAutosize, CircularProgress } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AppContext } from '../../../hooks/AppContext';
import { ImageUploadIPFS } from '../IPFS/ImageUploadIPFS';
import ipfsHelper from '../../../services/ipfs-helper';


export const AddProof = (props) => {
    const [proofText, setProofText] = useState('');
    const [proofImage, setProofImage] = useState('');
    const [isSendingProof, setIsSendingProof] = useState(false);

    const {updateCrowdService, account, crowdService} = useContext(AppContext)

    const clearForm = () => {
      setProofText('');
      setProofImage('');
    };

    const addProof = async (event) => {    
        if(account) {
          setIsSendingProof(true);
          var textPath = await ipfsHelper.uploadContent(proofText);
          await crowdService.methods.proof(props.serviceId, textPath, proofImage).send({
              from: account,
          });
          setIsSendingProof(false);
          updateCrowdService();
          clearForm();
        }
    
      }

    return (<><Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography><strong>Add Proof</strong> (I have done or will do this!)</Typography>

        </AccordionSummary>
        <AccordionDetails>

        <form onSubmit={addProof}>

          <ImageUploadIPFS image={proofImage} setImage={setProofImage} />

          <TextareaAutosize
            value={proofText}
            onChange={(e) => setProofText(e.target.value)}
            aria-label="empty textarea"
            placeholder="Text"
            style={{ width: '100%', marginBottom: '16px', padding: '16px', fontSize: '16px', fontFamily: 'inherit' }}
            sx={{ mb: 2, width: '100%' }}
          />
          <br />

          { !isSendingProof ?
                <Button disabled={!account} disableRipple={false} variant="contained" onClick={() => {
                  addProof();
                }}>
                  {account ? 'Add Proof' : 'Add Proof (Connect wallet before)' }
                </Button> :
                <Button disabled disableRipple={false} variant="contained" >Add Proof <CircularProgress size={10} /></Button>
          }
        </form>
        </AccordionDetails>
      </Accordion></>);
}