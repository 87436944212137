import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { InjectedConnector } from "@web3-react/injected-connector";


const POLLING_INTERVAL = 12000;
const RPC_URLS = {
  // 1: "https://mainnet.infura.io/v3/379968745ce84201815654796474c398",
  // 4: "https://rinkeby.infura.io/v3/379968745ce84201815654796474c398",
  56: "https://falling-floral-dew.bsc.quiknode.pro/1dcb2fc69428ba062a4d60fff275028189496906/",
  97: "https://falling-floral-dew.bsc.quiknode.pro/1dcb2fc69428ba062a4d60fff275028189496906/"
};

// console.log(RPC_URLS[1]);

// const injected = new InjectedConnector({ supportedNetworks: [1, 3, 4, 5, 42] });
export const injected = new InjectedConnector({
    supportedChainIds: [56, 97] //[1, 3, 4, 5, 42, 56, 97]
  });

// const coinbaseWallet = new WalletLinkConnector({
//     //  url: `https://mainnet.infura.io/v3/${process.env.INFURA_KEY}`,
//     url: `https://rinkeby.infura.io/v3/379968745ce84201815654796474c398`,
//     appName: "Web3-react Demo",
//     supportedChainIds: [1, 3, 4, 5, 42],
// });

export const walletlink = new WalletLinkConnector({
    url: RPC_URLS[1],
    appName: "CrowdService"
  });


export const walletconnect = new WalletConnectConnector({
    rpc: { 1: RPC_URLS[1], 4: RPC_URLS[4], 56: RPC_URLS[56], 97: RPC_URLS[97] },
    bridge: "https://bridge.walletconnect.org",
    qrcode: true,
    pollingInterval: POLLING_INTERVAL
  });

// const walletConnect = new WalletConnectConnector({
//     // rpcUrl: `https://mainnet.infura.io/v3/${process.env.INFURA_KEY}`,
//     infuraId: "379968745ce84201815654796474c398",
//     // rpcUrl: `https://rinkeby.infura.io/v3/379968745ce84201815654796474c398`,
//     // bridge: "https://bridge.walletconnect.org",
//     qrcode: true,
// });

// export const connectors = {
//     injected: injected,
//     walletConnect: walletConnect,
//     coinbaseWallet: coinbaseWallet,
// }