import './App.css';

import React from 'react';
import { Container } from '@mui/material';

import { Header } from './components/Shared/Header/Header';
import { Route, Routes } from 'react-router-dom';
import { Service } from './components/Pages/Service/Service';
import { About } from './components/Pages/About/About';
import { Payouts } from './components/Pages/Payouts/Payouts';
import { ContractInfo } from './components/Pages/ContractInfo/ContractInfo';
import { Start } from './components/Pages/Start/Start';
import { AppContextProvider } from './hooks/AppContext';
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from "@ethersproject/providers";

require('dotenv').config();

function App(props) {

  function getLibrary(provider) {
    const library = new Web3Provider(provider);
    library.pollingInterval = 8000;
    return library;
  }

  return (    
    <>    
            <Web3ReactProvider getLibrary={getLibrary}>
            <AppContextProvider>
              <Header />

              <Container maxWidth="lg" sx={{marginTop: '88px'}}>
                <Routes>
                  <Route path="/" element={<Start />} />
                  <Route path='/about' element={<About />} />
                  <Route path='/payouts' element={<Payouts />} />
                  <Route path='/contract' element={<ContractInfo />} />
                  <Route path='/tokenomics' element={<ContractInfo />} />
                  <Route path="/:serviceId" element={<Service />} />
                  <Route path="/*" element={<><span>NotFound</span></>} />
                </Routes>
              </Container>
            </AppContextProvider>

            </Web3ReactProvider>  
    </>
  );
}

export default App;
