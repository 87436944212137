import { Button, CircularProgress } from "@mui/material"
import { useState, useContext } from "react";
// import crowdService from "../../../crowdService";
import { AppContext } from "../../../hooks/AppContext";

export const ProofVote = (props) => {
    // const [voted, setVoted] = useState(false);
    const [isVoting, setIsVoting] = useState(false);

    const {updateCrowdService, account, crowdService} = useContext(AppContext)


    const vote = async () => {
        setIsVoting(true);
        await crowdService.methods.vote(props.serviceId, props.proofAddress).send({
            from: account
        });
        setIsVoting(false);
        updateCrowdService();
    };
    
    return (<>
        { props.staker && props.staker.votedFor !== props.proofAddress && !isVoting && <Button onClick={(e) => {vote()}}>Vote</Button> }
        { ((props.staker && props.staker.votedFor === props.proofAddress) || isVoting) && <Button disabled >Voted</Button> }

        { isVoting && <CircularProgress />}
    </>
        )
}