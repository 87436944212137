import { useEffect, useState, useContext } from "react";
// import crowdService from "../../../crowdService";
import { AppContext } from "../../../hooks/AppContext";


export const ProofVotes = (props) => {
    const [votes, setVotes] = useState(0);
    const [denies, setDenies] = useState(0);
    const {updateCrowdService, account, crowdService} = useContext(AppContext)

    useEffect(() => {
        let isCleaning = false;

        const loadVotes = async () => {
            const votes = await crowdService.methods.votePercentage(props.serviceId, props.proofAddress).call();
            if(!isCleaning) {
                setVotes(votes);
            }
        };
        const loadDenyPercentage = async () => {
            const denies = await crowdService.methods.denyPercentage(props.serviceId, props.proofAddress).call({
                from: account
            });
            if(!isCleaning) {
                setDenies(denies);
            }
        }

        loadVotes();
        loadDenyPercentage();

        return () => {
            isCleaning = true;
        }
    
    }, [props.serviceId, props.proofAddress, updateCrowdService, account, crowdService])
    

    return (<>
        <div>Votes: {votes}%</div>
        <div>Reports: {denies}%</div>
    </>)
};