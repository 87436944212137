import { PhotoCamera } from "@mui/icons-material";
import { Button, CircularProgress, Grid, Stack, TextField } from "@mui/material";
import { useState } from "react";
import propTypes from 'prop-types'


import { create } from "ipfs-http-client";

import { styled } from '@mui/material/styles';
import { Image } from "mui-image";


export const ImageUploadIPFS = ({image, setImage}) => {
    const [isUploading, setIsUploading] = useState(null);

    const Input = styled('input')({
        display: 'none',
    });
    
    const client = create('https://ipfs.infura.io:5001/api/v0');

    const retrieveFile = (e) => {
        setIsUploading(true);
        const data = e.target.files[0];
        const reader = new window.FileReader();
        reader.readAsArrayBuffer(data);
        reader.onloadend = async () => {
            var file = Buffer(reader.result);

            try {
                const created = await client.add(file);
                setImage(created.path);
                setIsUploading(false);
            } catch (error) {
            }    
        }
    
        e.preventDefault();  
    }

    return <Grid container spacing={2} mb={2}>
                <Grid item xs={7} sm={9}>
                    <TextField label="Image (url)" variant="outlined" value={image} onChange={(e) => setImage(e.target.value)} sx={{ mb: 2, width: '100%' }} /><br />
                </Grid>
                <Grid item xs={5} sm={3} sx={{width: 200, height: 100}}>
                    { image ? <Image src={image.startsWith('http') ? image : `https://ipfs.infura.io/ipfs/${image}`} fit="scale-down" /> :
                        <Stack direction="row" alignItems="center" spacing={2} mb={2}>
                            <label htmlFor="contained-button-file">
                                <Input accept="image/*" id="contained-button-file" multiple type="file" onChange={retrieveFile}  />
                                { !isUploading ? <Button variant="contained" component="span">
                                    Upload&nbsp;&nbsp;<PhotoCamera />
                                </Button> : <Button disabled variant="contained" component="span">
                                    Upload&nbsp;&nbsp;<CircularProgress />
                                </Button> } 
                            </label>
                        </Stack>
                    }
                </Grid>
            </Grid>
            };

ImageUploadIPFS.propTypes = {
    image: propTypes.string,
    setImage: propTypes.func
};