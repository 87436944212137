import React, { useContext } from 'react';
import web3 from './../../../web3';
// import crowdService from '../../../crowdService';
import { useState } from 'react';

import { Typography, Button, TextField, Accordion, AccordionSummary, AccordionDetails, TextareaAutosize, Slider, Divider, CircularProgress, Grid, FormControlLabel, Switch } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AppContext } from '../../../hooks/AppContext';
import { ImageUploadIPFS } from '../IPFS/ImageUploadIPFS';
import ipfsHelper from '../../../services/ipfs-helper';
// import { useWeb3React } from "@web3-react/core";



export const CreateAndStake = () => {
    const [createTitle, setCreateTitle] = useState('');
    const [createText, setCreateText] = useState('');
    const [createImage, setCreateImage] = useState('');
    const [createLink, setCreateLink] = useState('');
    const [createValue, setCreateValue] = useState(0.0);
    const [createRequireVotePercents, setCreateRequireVotePercents] = useState(0);
    const [createStakeDays, setCreateStakeDays] = useState(30); // days

    const [createTag1, setCreateTag1] = useState('');
    const [createTag2, setCreateTag2] = useState('');
    const [createTag3, setCreateTag3] = useState('');
    const [createTag1Error, setCreateTag1Error] = useState(false);
    const [createTag2Error, setCreateTag2Error] = useState(false);
    const [createTag3Error, setCreateTag3Error] = useState(false);

    const [otherProofs, setOtherProofs] = useState(false);


    const [isCreating, setIsCreating] = useState(false);

    const {updateCrowdService, crowdService, account} = useContext(AppContext)

    // const { account } = useWeb3React();


    const clearForm = () => {
      setCreateTitle('')
      setCreateText('');
      setCreateImage('');
      setCreateLink('');
      setCreateValue('');
      setCreateRequireVotePercents(0);
      setCreateStakeDays(30);

      setCreateTag1('');
      setCreateTag2('');
      setCreateTag3('');
      setCreateTag1Error(false);
      setCreateTag2Error(false);
      setCreateTag3Error(false);
    };


    const createAndStake = async (event) => {

        setIsCreating(true);
        var textPath = await ipfsHelper.uploadContent(createText);

        try {
          // console.log(account);
          await crowdService.methods.createAndStake(createTitle, textPath, createImage, createLink, createRequireVotePercents, createStakeDays, createTag1, createTag2, createTag3, otherProofs).send({
            from: account,
            value: web3.utils.toWei(createValue.toString(), 'ether'),
          });  
        } catch(err) {
          console.log(err);
        }
        setIsCreating(false);
        updateCrowdService();
        clearForm();        
      }

    return (<>      
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Create Service</Typography>
        </AccordionSummary>
        <AccordionDetails>

        <form onSubmit={createAndStake}>

          <TextField label="Title" variant="outlined" value={createTitle} onChange={(e) => setCreateTitle(e.target.value)} sx={{ mb: 2, width: '100%' }} /><br />
          <ImageUploadIPFS image={createImage} setImage={setCreateImage} />
          <TextField label="Link (url)" variant="outlined" value={createLink} onChange={(e) => setCreateLink(e.target.value)} sx={{ mb: 2, width: '100%' }} /><br />

          <TextareaAutosize
            value={createText}
            onChange={(e) => setCreateText(e.target.value)}
            aria-label="empty textarea"
            placeholder="Text"
            style={{ width: '100%', marginBottom: '16px', padding: '16px', fontSize: '16px', fontFamily: 'inherit' }}
            sx={{ mb: 2, width: '100%' }}
          />

          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} sm={4}>
              <TextField fullWidth label="Tag" variant="outlined" value={createTag1} error={createTag1Error} onChange={(e) => {
                setCreateTag1(e.target.value);
                setCreateTag1Error(e.target.value !== '' && !e.target.value.match(/^[a-öA-Ö0-9]+$/));
              }} width="100%" />
            </Grid>
            { createTag1 && <Grid item xs={12} sm={4}>
              <TextField fullWidth label="Tag" variant="outlined" value={createTag2} error={createTag2Error}  onChange={(e) => {
                setCreateTag2(e.target.value);
                setCreateTag2Error(e.target.value !== '' && !e.target.value.match(/^[a-öA-Ö0-9]+$/));
              }} />
            </Grid>}
            { createTag2 &&  <Grid item xs={12} sm={4}>
              <TextField fullWidth label="Tag" variant="outlined" value={createTag3} error={createTag3Error}  onChange={(e) => {
                setCreateTag3(e.target.value);
                setCreateTag3Error(e.target.value !== '' && !e.target.value.match(/^[a-öA-Ö0-9]+$/));
              }} />
            </Grid> }
          </Grid>

          Require {createRequireVotePercents}% of votes
          <Slider
            aria-label="Required voting percentage"
            defaultValue={0}
            value={createRequireVotePercents}
            valueLabelDisplay="auto"
            step={1}
            min={0}
            max={100}
            onChange={(e) => setCreateRequireVotePercents(e.target.value)}
          />
          <br />
          <br />
          <Divider>Stake</Divider>
          <br />

          <TextField label="BNB to lock" variant="outlined" inputProps={{ type: 'number', step: '0.000001' }} value={createValue} onChange={(e) => setCreateValue(e.target.value)} sx={{ mb: 2, width: '100%' }} />
          <br />

          Timelocking for: {createStakeDays} days
          <Slider
            aria-label="Timelocking days"
            defaultValue={30}
            valueLabelDisplay="auto"
            step={1}
            min={1}
            max={365*5}
            value={createStakeDays}
            onChange={(e) => setCreateStakeDays(e.target.value)}
          />


          <br />
          <Divider>Proof</Divider>
          <br />
          <FormControlLabel
            control={<Switch
                checked={otherProofs}
                onChange={(event) => {setOtherProofs(event.target.checked)}}
                inputProps={{ 'aria-label': 'controlled' }}
                value={otherProofs}
            />}
            label="No other proofs"
            labelPlacement='start'
          /> 
          <br />
          <br />

          { !isCreating ?  
            <Button disabled={createTag1Error || createTag2Error || createTag3Error} disableRipple={false} variant="contained" onClick={() => { createAndStake(); }}>Create</Button> :  
            <Button disableRipple={false} variant="contained" disabled>Create <CircularProgress size={10} /></Button>
          }
          <br />

        </form>


        </AccordionDetails>
      </Accordion></>);
}