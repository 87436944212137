import { createContext, useEffect, useState } from "react";
import propTypes from 'prop-types'
// import crowdService from "../crowdService";
import { crowdServiceAddress, crowdServiceAbi  } from "../contracts/crowdService";
import { cwdsTokenAbi } from "../contracts/cwdsToken";
import defaultweb3 from '../web3';
import { useWeb3React } from "@web3-react/core";



const Web3 = require('web3');
 
export const AppContext = createContext({});

export const AppContextProvider = (props) => {    
    const { ethereum } = window;
    const { account } = useWeb3React();


    const [crowdServiceUpdated, setCrowdServiceUpdated] = useState(Date.now());
    const [web3Provider, setWeb3Provider] = useState(defaultweb3);

    const [web3, setWeb3] = useState(defaultweb3);
    const [crowdService, setCrowdService] = useState();
    const [cwdsToken, setCwdsToken] = useState();

    const [cwdsTokenBalance, setCwdsTokeBalance] = useState(0);
    const [etherBalance, setEtherBalance] = useState(0);

    // const [account, setAccount] = useState(null)
    const [manager, setManager] = useState(null);

    const updateCrowdService = () => {
        setCrowdServiceUpdated(Date.now())
    }



    useEffect(() => {

        // console.log('web3provider', web3Provider);
        const load = async () => {
            var web3 = new Web3(web3Provider);
            setWeb3(web3);

            const crowdService = new web3.eth.Contract(crowdServiceAbi, crowdServiceAddress);
            setCrowdService(crowdService);


            const cwdsTokenAddress = await crowdService.methods.token().call();
            // console.log(cwdsTokenAddress);
            const cwdsToken = new web3.eth.Contract(cwdsTokenAbi, cwdsTokenAddress);
            setCwdsToken(cwdsToken);
        };

        if(web3Provider) {    
            load();
        }
    }, [web3Provider])



    useEffect(() => {
        const load = async () => {
            // console.log('loading accounts');
            ethereum.isConnected();
            // console.log(connected);

            // const accountsWeb3 = await web3.eth.getAccounts();
            // const accounts = await ethereum.request({ method: 'eth_accounts' });

            // const accounts = await ethereum.enable();
            // const account = accounts[0];

            // setAccount(account);
            // console.log('accounts', accounts, account);

            if(crowdService)
            {
                const manager = await crowdService.methods.manager().call();
                setManager(manager);    
            }

         };

         if(ethereum){
            load();
         }
    }, [ethereum, crowdService])


    // useEffect(() => {
    //     if(ethereum) {
    //         console.log('adding accounts event');
    //         ethereum.on("accountsChanged", accounts => {
    //             console.log('accountsChanged', accounts);
    
    //         //   if (accounts.length > 0) setAccount(accounts[0]);
    //         //   else setAccount("");
    //         });    
    //     }
    // }, [ethereum]);



    useEffect(() => {
        const load = async () => {
            var cwdsBalance = await cwdsToken.methods.balanceOf(account).call();
            setCwdsTokeBalance(web3.utils.fromWei(cwdsBalance, 'ether'));

            var ethBalance = await web3.eth.getBalance(account);
            setEtherBalance(web3.utils.fromWei(ethBalance, 'ether'));
        };
        
        setCwdsTokeBalance(0);
        setEtherBalance(0);

        if(account && cwdsToken) {
          load();
        }
      }, [crowdServiceUpdated, cwdsToken, account, web3]);


    return (
        <AppContext.Provider
            value={{
                crowdServiceUpdated,
                updateCrowdService,
                setWeb3Provider,
                web3,
                crowdService,
                cwdsToken,
                account,
                manager,
                cwdsTokenBalance,
                etherBalance,
            }}
        >
            {props.children}
        </AppContext.Provider>
    );
}


AppContextProvider.propTypes = {
    children: propTypes.any,
};