import { Typography, Skeleton } from '@mui/material';
import propTypes from 'prop-types'
import { useEffect, useState } from 'react';
import ipfsHelper from '../../../services/ipfs-helper';

export const IPFSText = ({textOrCid}) => {
    const [text, setText] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const loadContent = async () => {
            if(textOrCid.indexOf(' ') === -1)
            {
                var serviceText = await ipfsHelper.getContent(textOrCid);
                setText(serviceText);
                setIsLoading(false);
            }
            else {
                setText(textOrCid);
                setIsLoading(false);
            }
        }

        if(textOrCid) {
            loadContent();
        }

    }, [textOrCid]);

    return <>{ !isLoading ? 
        (text && <Typography component="p" whiteSpace="pre-line">{text}</Typography>) : 
        <>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
        </>
     } </>
}

IPFSText.propTypes = {
    textOrCid: propTypes.string,
};