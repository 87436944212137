import { Avatar } from '@mui/material';

export const CryptoAvatar = ({address}) => {

    const stringToColor = (string) => {
        let hash = 0;
        let i;
      
        for (i = 0; i < string.length; i += 1) {
          hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
      
        let color = '#';
      
        for (i = 0; i < 3; i += 1) {
          const value = (hash >> (i * 8)) & 0xff;
          color += `00${value.toString(16)}`.slice(-2);
        }

        return color;
      };
    const stringAvatar = (address) => {

        var name = address.replace('0x', '');
        var color = stringToColor(name);
        return {
          sx: {
            bgcolor: color,
            fontSize: '14px',
          },
          children: `${name.substring(0, 2)}.${name.substring(name.length-2)}`,
        };
      };

      
    return (<>
        {address && <Avatar {...stringAvatar(address.toLowerCase())} /> }
    </>);
};