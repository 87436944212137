import { useState, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";

import { injected } from "../connectors";

export function useEagerConnect() {
  const { activate, active } = useWeb3React();

  const [tried, setTried] = useState(false);

  useEffect(() => {
      // console.log('something!');
    injected.isAuthorized().then(isAuthorized => {
      if (isAuthorized) {

        // console.log('something is authorized!');

        activate(injected, undefined, true).catch(() => {
          setTried(true);
        });
      } else {
        setTried(true);
      }
    });
  }, [activate]); // intentionally only running on mount (make sure it's only mounted once :))

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (!tried && active) {
      setTried(true);
    }
  }, [tried, active]);

  return tried;
}

export function useInactiveListener(suppress = false) {
  const { active, error, activate } = useWeb3React();
  // console.log('something something 1', active, error, suppress);
 
  useEffect(() => {

    // const loadAccount = async () => {
    //     // const accounts = await ethereum.request({ method: 'eth_accounts' });
    //     // console.log('wc account', accounts);
    // };

      // console.log('something something', active, error, suppress);
    const { ethereum } = window;
    if (ethereum && ethereum.on && !active && !error && !suppress) {

        // loadAccount();


      const handleChainChanged = chainId => {
        // console.log("chainChanged", chainId);
        activate(injected);
      };

      const handleAccountsChanged = accounts => {
        // console.log("accountsChanged", accounts);
        if (accounts.length > 0) {
          activate(injected);
        }
      };

      const handleNetworkChanged = networkId => {
        // console.log("networkChanged", networkId);
        activate(injected);
      };

      ethereum.on("chainChanged", handleChainChanged);
      ethereum.on("accountsChanged", handleAccountsChanged);
      ethereum.on("networkChanged", handleNetworkChanged);

      return () => {
          // console.log('removing');
        if (ethereum.removeListener) {
          ethereum.removeListener("chainChanged", handleChainChanged);
          ethereum.removeListener("accountsChanged", handleAccountsChanged);
          ethereum.removeListener("networkChanged", handleNetworkChanged);
        }
      };
    }

    return () => {};
  }, [active, error, suppress, activate]);
}
