import React, { useContext } from 'react';
import web3 from './../../../web3';
// import crowdService from './../../../crowdService';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Typography, Button, Divider, CircularProgress, FormControl, InputLabel, MenuItem, Select, Autocomplete, TextField } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea, CardActions } from '@mui/material';
import { Masonry } from '@mui/lab';
import { Image } from 'mui-image';
import LockClockIcon from '@mui/icons-material/LockClock';
import { AppContext } from '../../../hooks/AppContext';
import { CryptoAvatar } from '../CryptoAvatar/CryptoAvatar';

export const TopList = () => {
    const [services, setServices] = useState(null);
    const navigate = useNavigate();
    const {crowdServiceUpdated, crowdService} = useContext(AppContext)
    const [tags, setTags] = useState(null);

    const [filter, setFilter] = useState('toplist');
    const [tagFilter, setTagFilter] = useState(null);


    useEffect(() => {
      const loadTags = async () => {
        const tags = await crowdService.methods.showTags().call();
        setTags(tags);
      };
      if(crowdService) {
        loadTags();
      }
    }, [crowdService]);

    useEffect(() => {    
        const load = async () => {
            var serviceIds;
            
            if(tagFilter) {
              serviceIds = await crowdService.methods.showServicesByTag(tagFilter).call();
            } else {
              serviceIds = await crowdService.methods.showServiceIds().call();
            }
    
            var services = [];
            for(var i = 0; i < serviceIds.length; i++)
            {
              const service = await crowdService.methods.services(serviceIds[i]).call();
              services.push(service);
            }


            if(filter === 'toplist') {
              services.sort((a, b) => {
                
                if(a.totalValue-0 < b.totalValue-0)
                {
                  return 1;
                }
                else if(a.totalValue-0 === b.totalValue-0)
                {
                  return 0;
                }
                return -1
              });
  
            }
            else if (filter === 'created') {
              services.sort((a, b) => {return a.createdTime < b.createdTime ? 1 : a.createdTime === b.createdTime ? 0 : -1});              
            }
            else if (filter === 'updated') {
              services.sort((a, b) => {return a.updatedTime < b.updatedTime ? 1 : a.updatedTime === b.updatedTime ? 0 : -1});              
            }

            setServices(services);  
          }
        if(crowdService) {
          setServices(null);
          load();  
        }
      }, [crowdServiceUpdated, filter, tagFilter, crowdService])

      const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };


    return (<Box my={6}>

      { tags && <Autocomplete
        disablePortal
        onChange={(event, newValue) => {
          setTagFilter(newValue);
        }}
        options={tags}
        sx={{ width: 200, float: 'right' }}
        renderInput={(params) => <TextField {...params} label="Filter by tag" />}
      /> }

      <FormControl sx={{ minWidth: 120, float: 'right', marginRight: 2, marginBottom: 4 }}>
        <InputLabel id="demo-simple-select-helper-label">Filter</InputLabel>
        <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={filter}
            label="Filter"
            onChange={handleFilterChange}
        >
          <MenuItem value="toplist">Toplist (Value)</MenuItem>
          <MenuItem value="created">Latest Created</MenuItem>
          <MenuItem value="updated">Latest Updated</MenuItem>
        </Select>
      </FormControl>
        
        { services ?
        <Masonry 
          columns={{ sm: 1, md: 2, lg: 3 }} 
          spacing={4}
          sx={{width: 'calc(100% + 30px)', overflow: 'hidden'}}
        >
        {services.map((service, index) => {
          if ( service.text !== '' )
          {
            return <Card key={service.id} sx={{ mb: 2, position: 'relative', overflow: 'visible' }}>
            <CardActionArea onClick={() => {
              navigate('/'+service.id);
            }} >

              { service.image && 
                <Image
                  fit="contain"
                  style={{maxWidth: '100%', width: 'auto'}}                                    
                  width="auto"
                  src={service.image.startsWith('http') ? service.image : `https://ipfs.infura.io/ipfs/${service.image}`}
                 />
              }
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  <LockClockIcon fontSize='small' /> <Typography component="span" fontWeight={'bold'}>{web3.utils.fromWei(service.totalValue, 'ether')}</Typography> BNB
                </Typography>

                <Typography gutterBottom variant="h5" component="h3">
                  {service.title}
                </Typography>

              </CardContent>

            </CardActionArea>
            <CardActions>
              <CryptoAvatar address={service.createdBy} /> &nbsp;&nbsp;
              <Divider orientation="vertical" flexItem />

              { service.link &&
                <>
                  <Button href={service.link} target="_blank" rel="noreferrer" size="small" color="primary">Link</Button>
                  <Divider orientation="vertical" flexItem />
                </>
              }

              <Button size="small" onClick={() => navigate('/'+service.id)} color="primary">
                Join
              </Button>
              <Divider orientation="vertical" flexItem />
              <Typography mx={2} component="span">{new Date(service.createdTime *1000).toLocaleDateString()}</Typography>
              {/* <Button size="small" color="primary">
                <ShareIcon fontSize="small" /> 
              </Button> */}
            </CardActions>

            { process.env.REACT_APP_THEME === 'kitty' && <img src="/static/kitty/kitty-face-hang.png" alt="" height="40px" style={{position: 'absolute', right: '-40px', top: '-10px',  }} /> }

          </Card>

          }
          return null;
        })}
      </Masonry>
        : <CircularProgress my={6} />}
        

      </Box>)
}