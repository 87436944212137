import { create } from "ipfs-http-client";

const client = create('https://ipfs.infura.io:5001/api/v0');

const ipfsHelper = {
    async uploadContent(content) {
        // const file = Buffer.from(content);
        const file = await client.add(content)
        return file.path;
    },
    async getContent(path) {
        // var file = await client.get(path);
        // console.log(file);

        var content =  await fetch('https://ipfs.infura.io/ipfs/' + path);        
        return await content.text();
    }
  };
  
  export default ipfsHelper;
  