import { Box, Typography } from '@mui/material';

export const About = () => {
    return (<>
        <Box sx={{ my: 4 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          About
        </Typography>
        <Typography variant='h6' component='h3'>
          The Power of Crypto and The Power of Numbers combined!
        </Typography>
        <ol>
          <li>This smart contract on Binance Smart Chain makes it easy to join hands and crowdfund anything.</li>
          <li>You can create or join any crowdfunded service with timelocking BNB.</li>
          <li>When value rises, the more likely it is that a service will be performed.</li>
          <li>Send in proof when the service was performed and the creator or manager can approve it and you will get paid.</li>
          <li>Stakers will recieve CWDS tokens as reward for participating in the crowdservice</li>
          <li>If a service was not performed. You can unstake and get all your BNB back when your time lock period ends.</li>
        </ol>

        <br />
        <br />
        <Typography variant='h6' component='h3'>
          The project:
        </Typography>
        <ul>
          <li>The two contracts powering this dapp are writtien in solidity and you can find them here: <br />
            CrowsService: <a href="https://bscscan.com/address/0x083fa0DEdC2a9e02ae7Db894184E52de3DDC206f" target="_blank" rel="noreferrer">0x083fa0DEdC2a9e02ae7Db894184E52de3DDC206f</a><br />
            CWDS token: <a href="https://bscscan.com/token/0xd83abd114ff3fa1038d75d54878ccecdcc6f9afd" target="_blank" rel="noreferrer" >0xd83abd114ff3fa1038d75d54878ccecdcc6f9afd</a>
          </li>
          <li>
            The dapp is build in React and stored in cdn. Content and images are stored in IPFS (InterPlanetary File System).
          </li>
        </ul>
      </Box>
    </>)
};