import { Box, Typography } from '@mui/material';
import { PayoutList } from '../../Shared/PayoutList/PayoutList';

export const Payouts = () => {
    return (<>

        <Box sx={{ my: 4 }}>
            <Typography variant="h5" component="h2" gutterBottom>
                Payouts        
            </Typography>
            <Typography>
                Ended CrowdServices that have been paid out and rewarded.
            </Typography>
            <PayoutList />
      </Box>
    </>)
};