import React, { useEffect, useState, useContext } from 'react';
import web3 from './../../../web3';
import propTypes from 'prop-types'
// import crowdService from '../../../crowdService';
// import { useWeb3React } from "@web3-react/core";


import { Typography, Button, TextField, Accordion, AccordionSummary, AccordionDetails, Slider, Switch, FormControlLabel, CircularProgress } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AppContext } from '../../../hooks/AppContext';

export const Stake = ({serviceId, staker}) => {
    const [createValue, setCreateValue] = useState('');
    const [createStakeDays, setCreateStakeDays] = useState(30); // days
    const [delegateVotes, setDelegateVotes] = useState(false);

    const [isStaking, setIsStaking] = useState(false);
    const [isUnStaking, setIsUnStaking] = useState(false);
    const [canUnstake, setCanUnstake] = useState(false);

    const {updateCrowdService, crowdService, account} = useContext(AppContext)

    // const { account } = useWeb3React();


    
    useEffect(() => {
        if(staker?.endTime) {
            setCanUnstake(new Date(staker.endTime * 1000).getTime() < new Date(Date.now()).getTime());            
        }

    }, [staker]);


    const clearForm = () => {
        setCreateValue('');
        setCreateStakeDays(30);
        setDelegateVotes(false);
    }

    const stake = async (event) => {
        setIsStaking(true);
        web3.eth.defaultAccount = account;

        // console.log('from', web3.utils.toChecksumAddress(account));
        try {
            await crowdService.methods.stake(serviceId, createStakeDays, delegateVotes).send({
                from: account,
                value: web3.utils.toWei(createValue.toString(), 'ether'),
                // gas: '7000000',
                // to: '0xf3166Fb9419faD606BC12495877F0aEd77AF2f46'
            });    
        } catch (err) {
            console.log(err);
        }
        // console.log('staker', result);
        setIsStaking(false);
        clearForm();
        updateCrowdService();
    }
    const unstake = async (event) => {
        setIsUnStaking(true);
        try {
            await crowdService.methods.unstake(serviceId).call({
                from: account
            });
        }
        catch (err) {
            console.log(err);
        }
        setIsStaking(false);
        clearForm();
        updateCrowdService();
    }

    return (<><Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography><strong>Join</strong> (I support this!)</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <form onSubmit={stake}>
                <TextField label="BNB to lock" variant="outlined" inputProps={{ type: 'number', step: '0.000001' }} value={createValue} onChange={(e) => setCreateValue(e.target.value)} sx={{ mb: 2, width: '100%' }} />
                <br />
                Timelocking for: {createStakeDays} days
                <Slider
                    aria-label="Timelocking days"
                    defaultValue={30}
                    valueLabelDisplay="auto"
                    step={1}
                    min={1}
                    max={365*5}
                    value={createStakeDays}
                    onChange={(e) => setCreateStakeDays(e.target.value)}
                /> 

                <FormControlLabel
                    control={<Switch
                        checked={delegateVotes}
                        onChange={(event) => {setDelegateVotes(event.target.checked)}}
                        inputProps={{ 'aria-label': 'controlled' }}
                        value={delegateVotes}
                    />}
                    label="Delegate vote to creator"
                    labelPlacement='start'
                 /><br />
                 <br />                

                { !isStaking ? <Button disabled={!account} disableRipple={false} variant="contained" onClick={() => {
                        stake();
                    }}>
                        Join {!account && '(Connect wallet before)'}
                    </Button> : 
                    <Button disabled disableRipple={false} variant="contained">
                        Join <CircularProgress size={10} />
                    </Button>
                }                


                { staker?.value > 0 && 
                    <Button style={{ float: 'right' }} disabled={isUnStaking || !canUnstake} disableRipple={false} variant="contained" onClick={() => {
                        unstake();
                    }}>
                        Leave { isUnStaking && <CircularProgress size={10} /> }
                    </Button>
                }

                
                
            </form>
        </AccordionDetails>
      </Accordion></>);
}

Stake.propTypes = {
    serviceId: propTypes.string,
    staker: propTypes.any,
};