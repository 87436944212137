import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#0057B8',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
  },
});

export const themeKitty = createTheme({
  palette: {
    primary: {
      main: '#ed008c',
    },
    secondary: {
      main: '#fefe00',
    },
    error: {
      main: red.A400,
    },
    info: {
      main: '#fefe00',
    },

    background: {
      default: "#eeeeee"
    },
    text: {
      // primary: "#ed008c",
      secondary: "00000057"
    }
  },
});


export default theme;