import { Box, Button, Menu, MenuItem, Typography, useTheme } from "@mui/material";
import { useWeb3React } from "@web3-react/core";
import { useContext, useEffect, useState } from "react";
import { injected, walletconnect, walletlink } from '../../../connectors';
import { CryptoAvatar } from '../CryptoAvatar/CryptoAvatar';
import { useEagerConnect, useInactiveListener } from "../../../hooks/ConnectHooks";
// import {
    // URI_AVAILABLE,
    // UserRejectedRequestError as UserRejectedRequestErrorWalletConnect
//   } from "@web3-react/walletconnect-connector";
import { AppContext } from "../../../hooks/AppContext";



export const WalletConnect = (props) => {    
    const theme = useTheme();
    const{ setWeb3Provider, cwdsTokenBalance, etherBalance} = useContext(AppContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [loggedInMenuOpen, setLoggedInMenuOpen] = useState(false);

    const [isConnected, setIsConnected] = useState(null);
    const { connector,
        // library,
        // chainId,
        account,
        activate,
        deactivate,
        // active,
        // error
    } = useWeb3React();

    // handle logic to recognize the connector currently being activated
    const [activatingConnector, setActivatingConnector] = useState();

    // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
    const triedEager = useEagerConnect();

    // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
    useInactiveListener(!triedEager || !!activatingConnector);
    

    // useEffect(() => {
    //     console.log('running lib and chain', library, chainId, active, error);
    // }, [library, chainId, active, error]);

    
    useEffect(() => {
        if(account) { setIsConnected(true); } 
        else { setIsConnected(false); }
    }, [account])


    // log the walletconnect URI
    // useEffect(() => {
    //     const logURI = uri => {
    //         console.log("WalletConnect URI", uri);
    //     };
    //     walletconnect.on(URI_AVAILABLE, logURI);

    //     return () => {
    //     walletconnect.off(URI_AVAILABLE, logURI);
    //     };
    // }, []);



    useEffect(() => {

        const connect = async () => {
            let { provider } = await connector.activate();
           setWeb3Provider(provider);
        };

        if (activatingConnector && activatingConnector === connector) {
        setActivatingConnector(undefined);
        } else {
        
            if(connector) {
                connector.getAccount().then((value) => {
                    // console.log('acount?', value);
                    setIsConnected(true);
                })
                connect();
            }
        }
    }, [activatingConnector, connector, setWeb3Provider]);



    // useEffect(() => {

    //     console.log('Web3 info', chainId, account, active, connector, library);

    // }, [chainId, account, active, isConnected, connector, library]);

    // const handleDisconnect = async () => {
    //     await ethereum.disable();
    //     await deactivate();
    // };

    const handleCoinbaseConnect = async () => {
        await activate(walletlink);
        // console.log('coinbaseWallet', result);
        setActivatingConnector(walletlink);
        setIsConnected(true);
    }

    const handleWalletConnect = async () => {
        await activate(walletconnect);
        // console.log('walletConnect', result);
        setActivatingConnector(walletconnect);
        setIsConnected(true);
    }

    const handleInjected = async () => {
        await activate(injected);
        // console.log('injected', result);
        setActivatingConnector(injected);
        setIsConnected(true);
    }


    const handleOpenClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };

    const handleLoggedInMenuOpenClick = (event) => {
        setAnchorEl(event.currentTarget);
        setLoggedInMenuOpen(true)
    };
    const handleLoggedInMenuClose = () => {
        setAnchorEl(null);
        setLoggedInMenuOpen(false)
    };

    const handleDisconnect = async () => {
        await deactivate();
        activatingConnector?.close()
    };


    return <>
        { isConnected != null && (!isConnected ?    
        <>
            <Button
                id="wallet-button" 
                sx={{  
                    width: 'auto',
                    padding: {xs: '5px', md: '6px 16px'},
                    fontSize: {xs: 10, md: 'initial'},
                    maxInlineSize: {xs: 'min-content', md: 'max-content'},
                    wordBreak: 'break-word',
                    marginTop: '-3px', marginBottom: '-3px'

                }} variant="contained" color="info" onClick={handleOpenClick}>Connect Wallet</Button>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                width="10"
                MenuListProps={{
                'aria-labelledby': 'wallet-button',
                }}
            >
                <MenuItem onClick={() => handleCoinbaseConnect()} sx={{gridTemplateColumns: '1fr auto', display: 'grid'}}>Coinbase Wallet <img src="/static/wallets/coinbaseWalletIcon.svg" alt="Coinbase wallet icon" height={24} fit="contain" float="right" style={{display: 'grid', objectPosition: 'right', paddingLeft: '20px'}} /></MenuItem>
                <MenuItem onClick={() => handleWalletConnect()} sx={{gridTemplateColumns: '1fr auto', display: 'grid'}}>Wallet Connect <img src="/static/wallets/walletConnectIcon.svg" alt="WalletConnect icon" height={24} fit="contain" style={{display: 'grid', objectPosition: 'right', paddingLeft: '20px'}} /></MenuItem>
                <MenuItem onClick={() => handleInjected()} sx={{gridTemplateColumns: '1fr auto', display: 'grid'}}>Metamask <img src="/static/wallets/metamask.png" alt="MetaMask icon" height={24} fit="contain" style={{display: 'grid', objectPosition: 'right', paddingLeft: '20px'}} /></MenuItem>
            </Menu>
        </> : <>
            <Button onClick={handleLoggedInMenuOpenClick} sx={{ marginTop: '-6px', marginBottom: '-6px'}} >
                <CryptoAvatar address={account} />
            </Button>
            <Menu
                id="logged-in-menu"
                anchorEl={anchorEl}
                open={loggedInMenuOpen}
                onClose={handleLoggedInMenuClose}
            >
                <Box sx={{padding: '5px 15px', breakInside: 'avoid'}}>
                    <Typography sx={{wordBreak: 'none'}}><img src="/static/bnb.svg" alt="BNB" width="16px" /> {etherBalance} BNB</Typography>
                    <Typography><img src="/logo192.png" alt="CWDS" width="16px" /> {cwdsTokenBalance} CWDS</Typography>
                </Box>

                <MenuItem onClick={handleDisconnect}>Disconnect</MenuItem>
            </Menu>
        </>)
        } 
    </>;

}